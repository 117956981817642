import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home/Home.vue'
// import Application from '../views/Application/Application.vue'
// import About from '../views/About/About.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/application',
//     name: 'Application',
//     component: Application
//   },
//   {
//     path: '/about',
//     name: 'About',
//     component: About
//   }
  // {
  //   // path: '/about',
  //   // name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
